// Scroll To

window.SiteReady(($) => {
  console.log('Init: Scroll To');

  // let $ScrollItems = $('.js-scroll_to, a[href*="#"]').not('.js-no_scroll_to, a[href*="#spu-"]');

  // $ScrollItems.on('click', onScrollTo);

  $('body').on('click', '.js-scroll_to, a[href*="#"]:not(.js-no_scroll_to):not([href*="#spu-"])', onScrollTo);

  function onScrollTo(e) {
    let $target = $(e.currentTarget);
    let target = $target[0];
    let id = target.hash;
    let $to = $(id);

    if (
      target.pathname !== window.location.pathname ||
      target.hostname !== window.location.hostname ||
      target.protocol !== window.location.protocol ||
      target.search !== window.location.search
    ) {
      return;
    }

    if ($to.length) {
      e.preventDefault();
      e.stopPropagation();

      scrollToElement(id);

      $(document).trigger('scroll_to');
    }
  }

  function scrollToElement(id) {
    let $to = $(id);

    if ($to.length) {
      let offset = $to.offset();
      let margin = parseInt($to.css('scroll-margin-top'), 10);

      scrollToPosition(offset.top - margin);
    }
  }

  function scrollToPosition(top) {
    let offset = 0;

    $('html, body').animate({ scrollTop: top - offset });
  }

  function scrollDistance(distance) {
    let offset = window.scrollY;

    $('html, body').animate({ scrollTop: distance + offset });
  }

  //

  window.scrollToElement = scrollToElement;
  window.scrollToPosition = scrollToPosition;
  window.scrollDistance = scrollDistance;

});