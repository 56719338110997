window.SiteReady(($) => {
  console.log('Init: Wake Lock');

  let locked = false;
  let wakeLock = null;

  function lock() {
    console.log('Wake Lock: Lock screen');

    if ('keepAwake' in screen) {
      screen.keepAwake = true;

      locked = true;
      checkState();
    } else if ('wakeLock' in navigator && !wakeLock) {
      navigator.wakeLock.request('screen')
        .then((wl) => {
          wakeLock = wl;

          wakeLock.addEventListener('release', () => {
            console.log('Wake Lock: Unlocked');
            wakeLock = null;

            locked = false;
            checkState();
          });

          locked = true;
          checkState();
        })
        .catch((e) => {
          console.warn('Wake Lock: ', e);
        });
    }
  }

  function unlock() {
    console.log('Wake Lock: Unlock screen');

    if ('keepAwake' in screen) {
      screen.keepAwake = false;

      locked = false;
      checkState();
    } else if ('wakeLock' in navigator && wakeLock) {
      wakeLock.release();
      // wakeLock = null;

      // locked = false;
      // checkState();
    }
  }

  function toggle() {
    if ('keepAwake' in screen) {
      if (screen.keepAwake) {
        unlock();
      } else {
        lock();
      }
    } else if ('wakeLock' in navigator) {
      if (wakeLock) {
        unlock();
      } else {
        lock();
      }
    }
  }

  function checkState() {
    if (locked) {
      $('.js-wakelock_toggle').addClass('js-wakelock_locked');
    } else {
      $('.js-wakelock_toggle').removeClass('js-wakelock_locked');
    }
  }

  if ('keepAwake' in screen || 'wakeLock' in navigator) {
    $('html').addClass('js-wakelock');
    // console.log('Wake Lock: Supported');

    $('.js-wakelock_toggle').on('click', toggle);
  } else {
    console.warn('Wake Lock: Not Supported');
  }

  window.siteWakeToggle = toggle;
  window.siteWakeLock = lock;
  window.siteWakeUnlock = unlock;
});