window.SiteReady(($) => {

  function cleanEventText(text) {
    return text.replace(/\r?\n|\r/g, '').replace(/\s\s+/g, ' ');
  }

  function pushDataLayerEvent(event) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);

    console.log(event);
  }

  function handleDataLayerEvent(e) {
    var event = $(this).data('layer-event');
    var parsed = {
      event: 'doleTrack'
    };

    for (var i in event) {
      if (event[i] == 'PAGE_URL') {
        // parsed[i] = window.location.pathname;
        parsed[i] = window.location.href;
      } else if (event[i] == 'ANCHOR_TEXT') {
        parsed[i] = cleanEventText($(this).text());
      } else if (event[i].trim() !== '') {
        parsed[i] = event[i];
      }
    }

    pushDataLayerEvent(parsed);
  }

  // Analytics Events / Data Layer

  $('.header a, .footer a, .nav_tray a').on('click', () => {
    let event = {
      event: 'doleTrack',
      eventCategory: 'nav click',
      // eventAction: cleanEventText($(this).text()),
      eventAction: $(this).attr('href'),
      eventLabel: window.location.href
    };

    pushDataLayerEvent(event);
  });

  $('.page_wrapper a').on('click', () => {
    let event = {
      event: 'doleTrack',
      eventCategory: 'link click',
      // eventAction: cleanEventText($(this).text()),
      eventAction: $(this).attr('href'),
      eventLabel: window.location.href
    };

    pushDataLayerEvent(event);
  });

  $('*[data-layer-event]:not(select)').on('click', handleDataLayerEvent);
  $('select[data-layer-event]').on('focus', handleDataLayerEvent);

  // Epsilon Events

  $('*[data-epsilon-event]').on('click', function() {
    var event = $(this).data('epsilon-event');

    pushDataLayerEvent(event);
  });

  // MikMak Events

  var trackMikMak = function(data) {
    if (data.action === 'buy') {
      var event = {
        event: 'doleTrack',
        eventCategory: 'product detail|Buy Now ',
        eventAction: 'click',
        eventLabel: window.location.href
      };

      pushDataLayerEvent(event);
    }
  };

  window.swnDataLayer = window.swnDataLayer || {};
  window.swnDataLayer.widget_callback = trackMikMak;

});