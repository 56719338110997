// import Background from 'formstone/jquery/background';
// import Checkpoint from 'formstone/jquery/checkpoint';
import Lightbox from 'formstone/jquery/lightbox';
import Mediaquery from 'formstone/jquery/mediaquery';
// import Navigation from 'formstone/jquery/navigation';
import Swap from 'formstone/jquery/swap';

window.Debounce = (callback, timeout) => {
  let timer = null;

  return (...args) => {
    window.clearTimeout(timer);

    timer = window.setTimeout(() => {
      callback(...args);
    }, timeout);
  };
}

window.SiteReady(($) => {
  console.log('Init: Main Site');

  let $window = $(window);
  let $body = $('body');


  // Tables

  $('.wp-block-table').find('table').wrap('<div class="table_wrapper"></div>');
  // $('main table').wrap('<div class="table_wrapper"></div>');


  // Scroll

  let newScroll = -1;
  let lastScroll = 0;
  let newShift = -1;
  let lastShift = 100;

  function onScroll() {
    newScroll = $window.scrollTop();
    newShift = newScroll;
  }

  function onRAF() {
    if (newScroll != lastScroll) {
      let scrollDir = (newScroll < lastScroll) ? 'up' : 'down';

      if (newScroll > 0) {
        $body.addClass('scrolled');
      } else {
        $body.removeClass('scrolled');
      }

      // if (newScroll > 200) {
      //   if (scrollDir == 'down') {
      //     if (newShift - lastShift > 80) {
      //       $body.addClass('shifted');

      //       lastShift = newShift;
      //     }
      //   } else {
      //     if (lastShift - newShift > 80) {
      //       $body.removeClass('shifted');

      //       lastShift = newShift;
      //     }
      //   }
      // }

      lastScroll = newScroll;
    }

    window.requestAnimationFrame(onRAF);
  }

  $window.on('scroll', onScroll);
  window.requestAnimationFrame(onRAF);

  onScroll();


  // Nav

  $(window).on('resize', (e) => {
    let maxHeight = 0;
    let $headers = $('.header_main');

    $headers.each((index, el) => {
      let height = $(el).find('.header_top')[0].offsetHeight;

      // el.style.setProperty('--header_height', height + 'px');
      if ( height > maxHeight ) {
        maxHeight = height;
      }
    });

    // $('body')[0].style.setProperty('--header_height', maxHeight + 'px');
    document.documentElement.style.setProperty('--header_height', maxHeight + 'px');

    let $header = $('.header_main.desktop');
    let $navs = $header.find('.header_expander .nav_main > .nav_item');

    $navs.each((ii, navEl) => {
      let $nav = $(navEl);
      let navHeight = $nav[0].offsetHeight;

      // $nav[0].style.setProperty('--expander_height', navHeight + 'px');
      $nav.data('height', navHeight + 'px');
    });

    // let expanderHeight = $header.find('.header_expander_inner')[0].offsetHeight;

    // // $header[0].style.setProperty('--expander_height', expanderHeight + 'px');
    // document.documentElement.style.setProperty('--expander_height', expanderHeight + 'px');

  }).trigger('resize');


  // Desktop Nav

  $('.js-desktop_nav .nav_item.has_subnav').on('mouseenter focusin', (e) => {
    let id = $(e.currentTarget).data('nav-id');

    openHeader(false);
    closeNavs();
    openNav(id);
  });

  $('.js-desktop_nav .nav_item:not(.has_subnav)').on('mouseenter focusin', (e) => {
    closeNavs();
  });

  $('.js-header').on('mouseenter focusin', (e) => {
    openHeader(false);
  }).on('mouseleave focusout', (e) => {
    closeHeader(false);
  });


  let menuOpen = false;
  let modalOpen = false;

  $('.js-header .header_menu').on('swap:activate', (e) => {
    menuOpen = true;
    openHeader(true);
  })
  .on('swap:deactivate', (e) => {
    menuOpen = false;
    closeHeader(true);
  });

  // $('.js-geo_modal_handle, .js-search_modal_handle').on('lightbox:open', (e) => {
  $(window).on('lightbox:open', (e) => {
    let $target = $(e.originalEvent.detail.el);

    if ($target.hasClass('js-modal_handle')) {
      modalOpen = true;
      openHeader(true);
    }
  })
  .on('lightbox:close', (e) => {
    let $target = $(e.originalEvent.detail.el);

    if ($target.hasClass('js-modal_handle')) {
      modalOpen = false;
      closeHeader(true);
    }
  });

  function openHeader(mobile) {
    $('.js-header').addClass('open');

    if (mobile) {
      $('.js-header').addClass('mobile_open');
    }
  }

  function closeHeader(mobile) {
    if (!menuOpen && !modalOpen) {
      $('.js-header').removeClass('open');

      if (mobile) {
        $('.js-header').removeClass('mobile_open');
      }

      closeNavs();
    }
  }

  function openNav(id) {
    let $handle = $('.js-desktop_nav [data-nav-id="' + id + '"]');
    let $nav = $('.js-desktop_expander [data-nav-id="' + id + '"]');
    let height = $nav.data('height');

    document.documentElement.style.setProperty('--expander_height', height);

    $('.js-desktop_expander').addClass('open');

    $handle.addClass('open');
    $nav.addClass('open');
  }

  function closeNavs() {
    $('.js-desktop_expander').removeClass('open');
    $('.js-desktop_nav [data-nav-id]').removeClass('open');
    $('.js-desktop_expander [data-nav-id]').removeClass('open');
    // $('.js-desktop_expander .has_image').removeClass('active');

    closeSubnavs();
  }

  // Images

  $('.js-desktop_expander .nav_item').on('mouseenter focusin', (e) => {
    e.stopPropagation();
    e.preventDefault();

    let $target = $(e.currentTarget);

    closeImages();

    if ($target.hasClass('has_image')) {
      $target.addClass('image_active');
    }
  });

  $('.js-desktop_expander .nav_item').on('mouseleave focusout', (e) => {
    closeImages();
  });

  function closeImages() {
    $('.js-desktop_expander .has_image').removeClass('image_active');
  }

  // Subnav Level 2

  $('.js-desktop_expander .subnav.level_2 > .nav_item.has_subnav').on('mouseenter focusin', (e) => {
    closeSubnavs();
    openSubnav($(e.currentTarget));
  });

  $('.js-desktop_expander .subnav.level_2 > .nav_item:not(.has_subnav)').on('mouseenter focusin', (e) => {
    closeSubnavs();
  });

  function openSubnav($el) {
    $el.addClass('open');
  }

  function closeSubnavs() {
    $('.js-desktop_expander .subnav .nav_item.open').removeClass('open');

    // closeNavImages();
  }

  // // Subnav Level 3

  // $('.js-desktop_expander .subnav.level_3 > .nav_item.has_image').on('mouseenter focusin', (e) => {
  //   closeNavImages();
  //   openNavImage($(e.currentTarget));
  // });

  // $('.js-desktop_expander .subnav.level_3 > .nav_item:not(.has_image)').on('mouseenter focusin', (e) => {
  //   closeNavImages();
  // });

  // function openNavImage($el) {
  //   $el.addClass('open');
  // }

  // function closeNavImages() {
  //   $('.js-desktop_expander .subnav .nav_item.has_image.open').removeClass('open');
  // }

  // $('.js-desktop_nav .nav_item.has_subnav').eq(0).trigger('focusin');
  // $('.js-desktop_expander .subnav.level_2 .nav_item.has_subnav').eq(0).trigger('focusin');
  // $('.js-desktop_expander .subnav.level_3 .nav_item.has_image').eq(0).trigger('focusin');


  // Mobile Nav

  $('.js-mobile_nav .nav_item.has_subnav > a').each((index, el) => {
    let $link = $(el);
    let $parent = $link.parent('.nav_item');
    let active = ($parent.hasClass('is_ancestor') || $parent.hasClass('is_active'));
    let html = '';
    let linkClass = 'js-nav_toggle_' + index;

    html += '<span class="toggle_handle js-swap js-nav_toggle';
    if (active) {
      linkClass += ' fs-swap-active';
      html += ' fs-swap-active';
    }
    html += '" data-swap-target=".js-nav_toggle_' + index + '"';
    if (active) {
      html += ' data-swap-active="true"';
    }
    // html += ' data-swap-options="{&quot;maxWidth&quot;:&quot;979px&quot;}"';
    html += '></span>';

    $link.append(html).next('.subnav').addClass(linkClass);
  });

  // $('.js-mobile_nav').on('navigation:open', function() {
  //   $('.js-mobile_nav').scrollTop(0);
  // });


  // // Filter Groups

  // $('.js-filter_group .js-filter_button').on('click', (e) => {
  //   let $target = $(e.currentTarget);
  //   let $parent = $target.parents('.js-filter_group');
  //   let $buttons = $parent.find('.js-filter_button');
  //   // let $select = $parent.find('.js-filter_select');
  //   let $items = $parent.find('.js-filter_item');
  //   let active = $target.data('filter');

  //   $buttons.removeClass('active');
  //   $target.addClass('active');

  //   if (active == 'all') {
  //     $items.show();
  //   } else {
  //     $items.hide().filter('[data-filter="' + active + '"]').show();
  //   }
  // });

  // $('.js-filter_group .js-filter_select').on('change', (e) => {
  //   let $target = $(e.currentTarget);
  //   let $parent = $target.parents('.js-filter_group');
  //   let $buttons = $parent.find('.js-filter_button');
  //   let active = $target.val();

  //   $buttons.filter('[data-filter="' + active + '"]').trigger('click');
  // });


  // Filters

  $('.js-jump_form select').on('change', (e) => {
    $(e.currentTarget).parents('.js-jump_form').submit();
  });

  $('.js-dynamic_select').on('change', (e) => {
    let $target = $(e.currentTarget);
    let val = $target.val();
    let label = $target.find('option:selected').data('label');

    $target[0].dataset.selected = val;
    $target.next('.js-filter_spacer').html(label);
  });

  $('.js-jump_select').on('change', (e) => {
    let $target = $(e.currentTarget);
    let val = $target.val();
    // let label = $target.find('option:selected').data('label');

    // window.dataLayer = window.dataLayer || [];

    // window.dataLayer.push({
    //   event: 'filter_select',
    //   filter_text: label,
    // });

    window.location.href = val;
  });


// function initResize(event) {
//   helperElement.innerHTML = event.target.querySelector(
//     "option:checked"
//   ).innerText;
//   resize(helperElement.offsetWidth);
// }

// function resize(width) {
//   const root = document.documentElement;
//   root
// }

  // $('.js-filter_form select').on('change', (e) => {
  //   let $target = $(e.currentTarget);
  //   let label = $target.find('option:selected').data('label');

  //   $target[0].dataset.selected = $target.val();
  //   $target.next('.js-filter_spacer').text(label);

  //   $target.parents('form').submit();
  // });


  // Scroll Next

  $('.js-scroll_next').on('click', (e) => {
    e.preventDefault();
    e.stopPropagation();

    let $parent = $(e.currentTarget).parents('.js-scroll_next_parent');
    // let $next = $parent.next();

    // if ($next.length) {
    //   window.scrollToElement($next);
    // }

    let height = $parent.outerHeight() - (window.scrollY - $parent[0].offsetTop);

    window.scrollDistance(height);
  });


  // Search / Newsleter Modal

  $(window).on('lightbox:open', (e) => {
    let $handle = $(e.detail.el);

    if ($handle.hasClass('js-search_modal_handle')) {
      setTimeout(() => {
        $('.fs-lightbox.search_lightbox').find('input[type="text"]').first().focus();
      }, 50);
    }

    if ($handle.hasClass('js-newsletter_modal_handle')) {
      setTimeout(() => {
        $('.fs-lightbox.newsletter_lightbox').find('input[type="email"]').first().focus();
      }, 50);
    }
  });


  // // Action bar resize

  // $(window).on('resize', (e) => {
  //   let $bars = $('.action_bar');

  //   $bars.each((index, el) => {
  //     let height = el.firstChild.offsetHeight;

  //     el.style.setProperty('--action_bar_height', height + 'px');
  //   })
  // }).trigger('resize');


  $('.js-print').on('click', function() {
    window.print();
  });


  // Filters

  if ($('.js-filter_form').length) {
    // $('.js-filters').on('change', 'select, [type="checkbox"]', function(e) {
    $('.js-filter_form').on('change', 'select', function(e) {
      $(this).parents('.js-filter_form').submit();
    });

    $('.js-filter_form').on('change', '[type="checkbox"]', function(e) {
      var $item = $(this);
      var id = $item.attr('id');
      var label = $item.next('label').text();

      if ($item.is(':checked')) {
        var html = '<button type="button" class="filters_token js-filters_token" data-id="' + id + '">' + label + '<span></span></button>';
        $('.js-filter_tokens').prepend(html);
      } else {
        $('.js-filter_tokens [data-id="' + id + '"]').remove();
      }

      $item.parents('.js-filter_form').addClass('has_changed has_tokens');

      updateFilterCount();
    });

    $('.js-filter_form').on('click', '.js-filters_token', function(e) {
      var $item = $(this);
      var id = $item.data('id');

      $('#' + id).prop('checked', false).trigger('change');
    });

    $('.js-filter_swap .js-swap').on('keydown', function(e) {
      if (e.keyCode == 32) {
        e.preventDefault();
        // e.stopPropagation();

        $(this).swap('activate');
      }
    });

    $(document).on('click' /* , focusin' */, function(e) {
      let windowWidth = window.innerWidth;

      if (windowWidth >= 740 && $(e.target).closest('.js-filter_swap').length == 0) {
        $('.js-filter_swap .js-swap').swap('deactivate');
      }
    });

    function updateFilterCount() {
      var html = '';
      var count = $('.js-filter_form .js-filter_token').length;

      if (count > 0) {
        html = '(' + count + ')';
      }

      $('.js-filter_form .js-filter_count').html(html);
    }

    updateFilterCount();
  }


  // Recipe

  // Leaving old work since this works slightly differently
  $('.js-recipe_steps').on('click', '.js-recipe_step', (e) => {
    e.preventDefault();
    e.stopPropagation();

    let $target = $(e.currentTarget);
    let $parent = $target.parents('.js-recipe_steps');
    let $items = $parent.find('.js-recipe_step');
    // let $video = $target.find('iframe').not('.ready');
    let index = $items.index($target);

    // $parent.find('iframe').each(function(index, iframe) {
    //   var $iframe = $(iframe);
    //   // var command = {
    //   //   'event': 'command',
    //   //   'func': 'pauseVideo'
    //   // };

    //   pauseVideo($iframe);

    //   // $iframe[0].contentWindow.postMessage(JSON.stringify(command), '*');
    // });

    let complete = $target.hasClass('complete');

    $items.removeClass('complete');

    if (complete) {
      $items.slice(0, index).addClass('complete');
    } else {
      $items.slice(0, index + 1).addClass('complete');
    }

    // $items.removeClass('complete active');
    // $items.slice(0, index + 1).addClass('complete');
    // $target.addClass('active');

    // if ($video.length > 0) {
    //   $video.attr('src', $video.data('src')).addClass('ready');
    // }
  });

  // $('.js-recipe_step_marker').on('click', function(e) {
  //   var $parent = $(this).parents('.js-recipe_step');

  //   if ($parent.hasClass('active')) {
  //     e.preventDefault();
  //     e.stopPropagation();

  //     $parent.next('.js-recipe_step').trigger('click');
  //   }
  // });


  // Modals

  let modals = [
    'geo',
    'search',
    'newsletter'
  ];

  for ( let m in modals ) {
    let modal = modals[m];

    $('a[href*="#' + modal + '_modal"').each((index, el) => {
      let $link = $(el);

      $link.addClass('js-no_scroll_to js-lightbox js-' + modal + '_modal_handle js-modal_handle')
        .attr('data-lightbox-options', '{"customClass":"' + modal + '_lightbox","ordinal":false}');
    });

    // $('a[href*="#newsletter_modal"').each((index, el) => {
    //   let $link = $(el);

    //   $link.addClass('js-no_scroll_to js-lightbox js-newsletter_modal_handle js-modal_handle')
    //     .attr('data-lightbox-options', '{"customClass":"newsletter_lightbox"}');
    // });

    // $('a[href*="#search_modal"').each((index, el) => {
    //   let $link = $(el);

    //   $link.addClass('js-no_scroll_to js-lightbox js-search_modal_handle js-modal_handle')
    //     .attr('data-lightbox-options', '{"customClass":"search_lightbox"}');
    // });
  }


  // Plugins

  // $('.js-navigation').navigation();
  // $('.js-background').background();
  $('.js-swap').swap();
  $('.js-lightbox').lightbox({
    videoProviders: {
      sproutvideo: {
        pattern: /(.*(vids\.io|sproutvideo\.com)\/.*)\?/,
        format: (parts) => {
          return parts[1];
        }
      }
    }
  });
  // $('.js-tabs').tabs();

  // $('.js-checkpoint, [data-checkpoint-animation]').checkpoint({
  //   offset: '100px',
  //   reverse: true
  // });

  // Geo Adaptors

  window.showGeoModal = window.showGeoModal || false;

  if (window.showGeoModal) {
    $('.js-geo_modal_handle')[0].click();
  }

});
