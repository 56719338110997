// Simple wrapper to expose ready function for use in block js files

import { iterate, ready } from 'formstone/utils';

let _siteReady = false;
let _mapsReady = false;
let _mapsReadyCallbacks = [];

//

window.SiteReady = (cb) => {
  // console.log(cb);
  ready(() => {
    if (!_siteReady) {
      console.log('Site Ready');
    }

    _siteReady = true;

    cb(jQuery);
  });
};

//

window.MapsReady = function(cb) {
  // Push to maps ready if nothing is ready
  if (!_mapsReady && !_siteReady) {
    _mapsReadyCallbacks.push(cb);
  } else {
    cb(jQuery);
  }
}

window.initializeGMaps = function() {
  console.log('Maps Ready');

  _mapsReady = true;

  if (!_siteReady) {
    // Push to site ready if GMaps loads first
    window.SiteReady(initMaps);
  } else {
    initMaps();
  }
}

function initMaps() {
  iterate(_mapsReadyCallbacks, (cb) => {
    cb(jQuery);
  });
}